// progress-bar styles
.progress-bar {
	width: 100%;
	height: 4px;
	background: $pri-pink;

	&:before {
		content: "";
	}
}
