// home-gallery styles
// .home-gallery {

// }

.home-gallery__header {
	position: relative;
	text-align: center;
	padding: 50px 0;

	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background: transparentize(#232323, .3);
	}

	* {
		position: relative;
		z-index: 1;
	}

	h2 {
		margin: 0;
	}

	h3 {
		font-size: calc-em(30px);
		color: $ter-pink;
	}
}

.home-gallery__photos {
	position: relative;
	top: -50px;

	.photo-list {
		@extend %list-unstyled;
		@extend %clearfix;
	}

	.desktop-gallery {
		display: none;
	}

	.photo {
		float: left;
		position: relative;
		width: (100% / 2);
		height: 0;
		padding-bottom: (100% / 2);

		&.pull-right {
			float: right;
		}

		img {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}

	.photo-2-1 {
		width: 100%;
	}

	.photo-1-2 {
		padding-bottom: 100%;
	}

	.slick-dots {
		margin-top: 10px;
		padding: 0;
		text-align: center;

		li {
			display: inline-block;
			margin: 5px;

			&.slick-active {
				button {
					background: #000;
				}
			}
		}

		button {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			border: 0;
			padding: 0;
			text-indent: 999px;
			overflow: hidden;
			outline: none;
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.home-gallery__header {
		padding: 50px 0 150px;
	}

	.home-gallery__photos {
		position: relative;
		top: -100px;
		margin: auto;
		max-width: 961px;

		.desktop-gallery {
			display: block;
		}

		.mobile-gallery {
			display: none;
		}

		img {
			opacity: 1;
			transition: opacity .5s ease-in-out;

			&.fade-out {
				opacity: 0;
			}
		}

		.photo {
			width: (100% / 4);
			padding-bottom: (100% / 4);
		}

		.photo-2-1 {
			width: (100% / 2);
		}

		.photo-1-2 {
			padding-bottom: (100% / 2);
		}

		.btn-refresh {
			display: block;
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			font-family: 'Lovelo', Arial, sans-serif;
			font-size: calc-em(17px);
			color: #747373;
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;
			transform: translateY(-50%);

			&:hover {
				.icon {
					transform: rotate(-360deg);
				}
			}

			.text {
				display: block;
			}

			.icon {
				display: block;
				position: relative;
				margin: auto;
				width: auto;
				height: auto;
				transition: transform .35s ease-in-out;
			}
		}
	}
}
