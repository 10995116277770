@font-face {
	font-family: 'Nickainley-Normal';
	src: url('../fonts/Nickainley-Normal.eot');
	src: url('../fonts/Nickainley-Normal.eot') format('embedded-opentype'),
	url('../fonts/Nickainley-Normal.woff') format('woff'),
	url('../fonts/Nickainley-Normal.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Lovelo';
	src: url('../fonts/Lovelo Black.eot');
	src: url('../fonts/Lovelo Black.eot') format('embedded-opentype'),
	url('../fonts/Lovelo Black.woff') format('woff'),
	url('../fonts/Lovelo Black.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'icomoon';
	src:  url('../fonts/icomoon.eot?o5k1iy');
	src:  url('../fonts/icomoon.eot?o5k1iy#iefix') format('embedded-opentype'),
	url('../fonts/icomoon.ttf?o5k1iy') format('truetype'),
	url('../fonts/icomoon.woff?o5k1iy') format('woff'),
	url('../fonts/icomoon.svg?o5k1iy#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

%icomoon {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
	content: "\f09a";
}
.icon-facebook-f:before {
	content: "\f09a";
}
.icon-instagram:before {
	content: "\f16d";
}

