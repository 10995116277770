// home-menu styles
.home-menu {
	padding: 50px 0;
	text-align: center;

	.section-title {
		margin: 0;
	}
}


.home-menu__article {
	@extend %clearfix;
	background: #fbfbfb;
	padding: 20px;

	.home-menu__page {
		// &:first-child {
		// 	margin-right: 100px;
		// }

		img {
			max-width: 100%;
		}
	}
}

.home-menu__category {
	display: none;
	opacity: 0;
	transition: opacity .35s ease-in-out;

	&.transit-in {
		opacity: 1;
	}
}

@media only screen and (min-width: $bpTablet) {
	.home-menu {
		padding: 80px 0 100px;
	}

	.home-menu__article {
		.home-menu__page {
			float: left;
			margin: 0;
			width: 50%;
			padding: 0 30px;

			img {
				margin-bottom: 20px;
			}
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.home-menu__article {
		padding: 50px;
	}
}
