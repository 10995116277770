// menu-nav styles
.menu-nav {

}

.menu-nav__tabs {
	@extend %list-unstyled;
	@extend %clearfix;
	margin: 20px 0;

	li {
		display: inline-block;
		margin: 0 10px 10px;

		&.active {
			.btn {
				background: #fff;
				color: $grey-1;
			}
		}

		a {
			text-transform: uppercase;
		}
	}
}


@media only screen and (min-width: $bpTablet) {
	.menu-nav__tabs {
		margin: 50px 0;

		li {
			margin: 0 10px 10px;
		}
	}
}

@media only screen and (max-width: $bpTablet - 1) {
	.menu-nav__tabs {
		li {
			display: inline-block;
			margin: 0 -3px 10px;
			padding: 0 5px;
			width: 50%;

			a {
				display: block;
			}
		}
	}
}

@media only screen and (max-width: $bpLgMobile - 1) {
	.menu-nav__tabs {
		li {
			display: block;
			width: 100%;

			a {
				display: block;
			}
		}
	}
}
