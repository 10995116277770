// site-footer styles

.site-footer {
	padding: 50px 0;
	background: $footer-bg-color;
	color: #fff;
}

.site-footer__logo {
	h1 {
		margin: 0;
	}
}

.site-footer__social {
	text-align: center;
	margin-bottom: 20px;

	.social-label {
		display: block;
		margin-bottom: 10px;
		font-family: 'Lovelo', Arial, sans-serif;
		font-size: calc-em(17px);
		text-transform: uppercase;
		vertical-align: middle;
	}

	.social-link {
		display: inline-block;
		margin: 0 15px;
		color: #fff;
		text-decoration: none;
		vertical-align: middle;

		&:hover {
			color: $ter-pink;
		}

		i {
			font-size: 2em;
		}

	}
}

.site-footer__legal {
	text-align: center;

	small {
		font-size: calc-em(15px);
		display: inline-block;
	}
}

@media only screen and (max-width: $bpTablet - 1) {
	.site-footer__logo {
		text-align: center;
		margin-bottom: 20px;
	}
}

@media only screen and (min-width: $bpDesktop) {
	.site-footer__social {
		text-align: right;
	}

	.site-footer__legal {
		text-align: right;
	}
}


@media only screen and (min-width: $bpLgDesktop) {
	.site-footer__social {
		.social-label {
			display: inline-block;
		}

		.social-link {
			margin-left: 25px;
			margin-right: 0;
		}
	}
}
