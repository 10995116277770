// event-box styles
.event-box {
	position: relative;
	margin-bottom: 20px;
	width: 100%;
	max-width: 480px;
	margin: 0 auto 20px;
	background: $event-box-bg;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	text-align: left;
}

.event-box__wrapper {
	width: 100%;
	padding-bottom: 100%;
}

.event-box__content {
	position: absolute;
	top: 30px;
	left: 30px;
	width: calc(100% - 60px);
	height: calc(100% - 60px);
	padding: 30px;
	background: transparentize(#f0f0f0, .03);
	color: #6c6c6c;

	h3 {
		margin: 0 0 10px;
		color: #303030;
	}

	p {
		margin: 0;
		font-weight: 500;
	}

}

.event-box__meta {
	display: block;
	margin-bottom: 20px;
	font-weight: 600;
	text-transform: uppercase;
}


@media only screen and (max-width: $bpTablet - 1) {
	.event-box {
		max-width: 360px;
	}

	.event-box__content {
		top: 20px;
		left: 20px;
		width: calc(100% - 40px);
		height: calc(100% - 40px);
		padding: 20px;

		h3 {
			font-size: calc-em(17px);
		}
	}
}

@media only screen and (min-width: $bpDesktop) and (max-width: $bpLgDesktop - 1) {
	.event-box__content {
		padding: 15px;
	}
}
