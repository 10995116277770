// home-events styles
.home-events {
	position: relative;
	padding: 50px 0 100px;
	background-size: cover;
	background-position: center center;
	text-align: center;
	overflow: hidden;

	.section-title {
		margin: 0 0 50px;
		text-shadow: 0 0 15px darken(#f9a9b9, 20%);
	}

	.lightbulbs {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		.lightbulb {
			position: absolute;
			width: 150px;

			&:nth-child(1) {
				left: 20px;
			}

			&:nth-child(2) {
				left: 0;
				top: -200px;
				margin-left: 15%;
			}

			&:nth-child(3) {
				left: 0;
				top: -150px;
				margin-left: 25%;
			}

			&:nth-child(4) {
				right: 0;
				top: -100px;
				margin-right: 20%;
			}

			&:nth-child(5) {
				right: 20px;
				top: -50px;
			}
		}
	}

}

.home-events__item {
	&:nth-child(1) {
		.event-box {
			background-image: url('../images/event-pattern1.jpg');
		}
	}

	&:nth-child(3) {
		.event-box {
			background-image: url('../images/event-pattern2.jpg');
		}
	}
}


@media only screen and (min-width: $bpTablet) and (max-width: $bpDesktop - 1) {
	.home-events__item {
		&:nth-child(3) {
			margin-left: 25%;
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.home-events {
		.lightbulbs {
			display: block;
		}
	}
}
