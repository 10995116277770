// staff-tabs styles
.staff-tabs {
	@extend %list-unstyled;

	li {
		display: inline-block;
		margin: 0 15px 15px 0;

		&.active {
			.btn {
				background: $grey-1;
				color: #fff;
			}
		}
	}

	.btn {
		text-transform: uppercase;
	}
}

@media only screen and (max-width: $bpTablet - 1) {
	.staff-tabs {
		margin: 0 -10px;

		li {
			display: block;
			float: left;
			margin: 0 0 10px;
			width: 50%;
			padding: 0 10px;
		}

		.btn {
			width: 100%;
		}
	}
}

@media only screen and (max-width: $bpLgMobile - 1) {
	.staff-tabs {
		text-align: center;

		li {
			display: block;
			float: none;
			width: 100%;
			text-align: center;
		}
	}
}
