%clearfix {
	@include clearfix;
}

.clearfix {
	@extend %clearfix;
}

%list-unstyled {
	@include normalise-list;
}

.list-unstyled {
	@extend %list-unstyled;
}

%background-cover {
	@include background-cover;
}

%visuallyhidden {
  @include visuallyhidden;
}

.visuallyhidden {
	@extend %visuallyhidden;
}

.no-desktop {
	@include desktop {
		display: none!important;
	}
}

.desktop-only {
	@include mobile {
		display: none!important;
	}
	@include tablet {
		display: none!important;
	}
}
