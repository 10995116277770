// home-about styles
.home-about {
	.img-grid {
		position: relative;
		padding: 0;

		.grid-bg-img {
			width: 100%;
			display: block;
		}
	}

	.header-wrapper {
		background: #fff;
		padding: 20px;
		text-align: center;

		h2 {
			margin: 0;
		}
	}

	.pattern-grid {
		background-image: url('../images/bg-pattern1.jpg');
	}

	.dark-grid {
		position: relative;
		color: #fff;
		overflow: hidden;


		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			background: transparentize(#282828, .1);
			opacity: 0;
			transition: opacity .25s ease-in-out;
		}

		&.content-shown {
			&:before {
				opacity: 1;
			}
		}

		.grid-bg-img {
			position: absolute;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
			transition: opacity .25s ease-in-out;

			// &.staff-img {
			// 	opacity: 0;
			// }

			// &.active {
			// 	opacity: 1;
			// }
		}


		.home-writeup {
			position: relative;
			z-index: 1;

			h3 {
				color: #fff;
			}
		}
	}

	#ourTeamContent {
		.home-writeup {
			display: none;
			opacity: 0;
			transform: translateY(20px);
			transition: opacity .25s ease-in-out, transform .25s ease-in-out;

			&.is-visible {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}
}

.staff-info {
	text-align: center;

	.staff-thumb {
		display: block;
		margin: auto;
		border-radius: 50%;
		border: 2px solid #fff;
		vertical-align: top;
	}

	.staff-details {
		display: inline-block;
		vertical-align: top;

		h3 {
			margin-top: 10px;
		}
	}
}

@media only screen and (min-width: $bpTablet) {
	.staff-info {
		text-align: left;

		.staff-thumb {
			display: inline-block;
			margin-right: 10px;
		}
	}
}
@media only screen and (min-width: $bpDesktop) {
	.home-about {
		.img-grid {
			.grid-bg-img {
				position: absolute;
				bottom: 0;
				right: 0;
				z-index: -1;
			}
		}
	}
}


@media only screen and (min-width: $bpLgDesktop) {
	.home-about {
		.header-wrapper {
			margin-left: -9999px;
			padding: 30px 30px 30px 9999px;
		}
	}
}
