// contact-info styles
.contact-info {
	margin: 0 -10px;
	padding: 30px;
	background: #fff;
	border-top: 5px solid $pri-pink;
	text-align: center;
}

.contact-info__layout {
	margin: 0 auto 20px;
	max-width: 100%;
	border-collapse: collapse;

	th, td {
		padding: 5px;
		vertical-align: top;
		text-align: left;
		word-wrap: break-word;
		word-break: break-word;
	}

	th {
		font-family: 'Lovelo', Arial, sans-serif;
		font-size: calc-em(17px);
		color: $ter-pink;
	}

	td {
		font-size: calc-em(18px);
		font-weight: 500;
		color: #6c6c6c;

		a {
			color: #6c6c6c;
			text-decoration: none;

			&:hover {
				color: $pri-pink;
			}
		}
	}
}

.contact-info__sub-title {
	display: inline-block;
	width: 50%;
	vertical-align: top;
}

.contact-info__sub-desc {
	display: inline-block;
	width: 50%;
}

@media only screen and (max-width: $bpLgMobile - 1) {
	.contact-info {
		padding: 20px 10px;
	}

	.contact-info__layout {
		th {
			font-size: calc-em(15px);
		}

		td {
			font-size: calc-em(16px);
		}
	}
}
