// Normalize Styles
@import 'node_modules/normalize.css/normalize';

@import './variables';
@import './mixins';
@import './grids';
@import './helpers';
@import './fonts';

// Import Modules
@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,600');
@import '../../node_modules/slick-carousel/slick/slick.scss';

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

html {
	font-size: 1em;
}

body {
	font: 100%/1.4 'Raleway', Arial, sans-serif;
	-webkit-animation: load .5s;
	-moz-animation: load .5s;
	animation: load .5s;
}

@-webkit-keyframes load { 0% { opacity: 0; } 20% { opacity: 0; } }
@-moz-keyframes load { 0% { opacity: 0; } 20% { opacity: 0; } }
@-o-keyframes load { 0% { opacity: 0; } 20% { opacity: 0; } }
@keyframes load { 0% { opacity: 0; } 20% { opacity: 0; } }

h1, h2, h3, h4, h5, h6 {
	font-family: 'Lovelo', Arial, sans-serif;
}

img, video, iframe {
	max-width: 100%;
}

a {
	transition: all .25s ease-in-out;
}

%container {
	position: relative;
	margin: 0 auto;
	max-width: 1280px;
	padding: 0 10px;
}

.container {
	@extend %container;
}

.container-fluid {
	padding: 0 10px;
}

.with-grid-content {
	padding: 0;
}

/*
* Need to create a series of grid-content for different width
*/
.grid-content {
	width: 100%;
}


.pull-right {
	float: right;
}

.pull-left {
	float: left;
}

/* Home */
#mainContent {
	margin-top: 62px;
}

.section-title {
	font-family: 'Nickainley-Normal', Arial, sans-serif;
	font-size: calc-em(60px);
	font-weight: normal;
	color: $sec-pink;
	text-shadow: 2px 2px 20px #fcd1d9;
}

.home-section {
	&.bg-dark-pattern {
		background-image: url('../images/bg-pattern2.jpg');
	}
}

.home-writeup {
	padding: 10px 30px;

	h3 {
		font-size: calc-em(30px);
		color: $ter-pink;
		margin: 0;
	}

	.staff-name {
		margin-bottom: 0;
	}

	.staff-designation {
		margin-top: 0;
		font-family: 'Raleway', Arial, sans-serif;
		font-size: calc-em(18px);
		text-transform: uppercase;
	}

	p {
		font-size: calc-em(18px);
		font-weight: 500;
	}
}

@media only screen and (max-width: $bpDesktop - 1) {
	.home-section {
		.row:first-child {

		.home-writeup {
			&:after {
				content: "";
				display: block;
				border-bottom: 1px solid #bbb;
			}
		}
	}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.pull-right {
		.home-writeup {
			padding-left: 10px;
		}
	}

	.pull-left {
		.home-writeup {
			padding-right: 10px;
		}
	}

	.grid-content {
		max-width: (1280 / 2) + px;
	}

	.home-writeup {
		padding: 30px 60px;
	}

}

@media only screen and (min-width: $bpLgDesktop) {
	.section-title {
		font-size: calc-em(100px);
	}

	.home-writeup {
		padding: 65px 85px;
	}
}


@import '../_modules/atoms/button/button';
@import '../_modules/atoms/progress-bar/progress-bar';

@import '../_modules/molecules/staff-tabs/staff-tabs';
@import '../_modules/molecules/menu-nav/menu-nav';
@import '../_modules/molecules/event-box/event-box';
@import '../_modules/molecules/contact-map/contact-map';
@import '../_modules/molecules/contact-info/contact-info';

@import '../_modules/organisms/home-banner/home-banner';
@import '../_modules/organisms/home-about/home-about';
@import '../_modules/organisms/home-menu/home-menu';
@import '../_modules/organisms/home-gallery/home-gallery';
@import '../_modules/organisms/home-events/home-events';
@import '../_modules/organisms/home-contact/home-contact';
@import '../_modules/organisms/site-header/site-header';
@import '../_modules/organisms/site-footer/site-footer';
