// home-banner styles
.home-banner {
	position: relative;

	.row {
		margin: 0;
	}
}

.home-banner__logo {
	img {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		height: 50%;
	}
}

.home-banner__image {
	img {
		width: 100%;
		display: block;
	}
}

.home-banner__col {
	padding: 0;
}

@media only screen and (max-width: $bpLgMobile - 1) {
	.home-banner__col {
		&:nth-child(2) {
			display: none;
		}
	}
}


@media only screen and (max-width: $bpDesktop - 1) {
	.home-banner__col {
		&:last-child {
			display: none;
		}
	}
}
