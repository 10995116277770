// home-contact styles


@media only screen and (min-width: $bpDesktop) {
	.home-contact {
		.contact-info-wrapper {
			position: relative;
		}

		.contact-info {
			position: absolute;
			width: 100%;
			bottom: 0;
			z-index: 1;
			margin-bottom: -150px;
		}
	}
}
