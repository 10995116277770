// button styles
.btn {
	display: inline-block;
	border-radius: 5px;
	padding: 10px 30px;
	font-weight: 500;
	text-decoration: none;
	transition: all .25s ease-in-out;
}

.btn-primary {
	font-family: 'Lovelo', Arial, sans-serif;
	font-weight: bold;
	background: $pri-pink;
	color: #fff;

	&:hover {
		background: lighten($pri-pink, 8%);
		box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, .5);
	}
}

.btn-stroke {
	border: 1px solid $grey-1;
	color: $body-text;

	&:hover {
		background: $grey-1;
		color: #fff;
	}
}

.btn-inverse {
	border: 1px solid #fff;
	color: #fff;

	&:hover {
		background: #fff;
		color: #000;
	}
}
