// site-header styles
.site-header {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999;
	background: $header-bg-color;

	.btn-book-now {
		position: absolute;
		top: 10px;
		right: 10px;
		padding: 8px 30px;
		font-family: 'Lovelo';
	}
}

.primary-nav {
	ul {
		@extend %list-unstyled;
	}

	a {
		display: inline-block;
		padding: 20px;
		font-family: 'Lovelo', Arial, sans-serif;
		color: $pink-2;
		text-decoration: none;
		text-transform: uppercase;

		&:hover {
			background: darken($header-bg-color, 10%);
		}
	}
}

@media only screen and (max-width: $bpDesktop - 1) {
	.site-header {
		text-align: right;
		padding: 14px 0 10px;

		.btn-book-now {
			position: relative;
			top: 0;
		}

		.progress-bar {
			position: absolute;
			top: 0;
			left: 0;
		}

		.btn-mobile-menu {
			position: absolute;
			left: 10px;
		}
	}

	.btn-mobile-menu {
		height: 36px;
		width: 46px;
		background: none;
		border: 0;
		padding: 0 5px;
		text-align: center;
		outline: 0;

		.line {
			display: inline-block;
			position: absolute;
			top: 5px;
			left: 5px;
			width: 36px;
			height: 4px;
			background: $pri-pink;
			transition: transform .25s ease-in-out, opacity .25s ease-in-out;

			&:nth-child(3), &:nth-child(4)  {
				transform: translateY(10px);
			}

			&:nth-child(5) {
				transform: translateY(20px);
			}
		}

		&.active {
			.line {
				&:nth-child(2), &:nth-child(5) {
					transform: translateY(10px);
					opacity: 0;
				}

				&:nth-child(3) {
					transform: translateY(10px) rotate(45deg);
				}

				&:nth-child(4) {
					transform: translateY(10px) rotate(-45deg);
				}
			}
		}
	}

	.primary-nav {
		position: absolute;
		text-align: center;
		top: 48px;
		left: 0;
		width: 100%;
		background: $header-bg-color;
		visibility: hidden;
		opacity: 0;
		transition: opacity .25s ease-in-out, visibility .25s ease-in-out;

		li {
			display: block;
		}

		a {
			display: block;
		}

		&.menu-open {
			visibility: visible;
			opacity: 1;
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.site-header {
		.btn-mobile-menu {
			display: none;
		}
	}

	.primary-nav {
		text-align: center;

		li {
			display: inline-block;

			&.active {
				a {
					background: darken($header-bg-color, 10%);
				}
			}
		}
	}
}

@media only screen and (min-width: $bpLgDesktop) {
	.primary-nav {
		a {
			padding: 20px 35px;
		}
	}
}
