/*
* Breakpoints
*/
$bpMobile: 320px;
$bpLgMobile: 480px;
$bpTablet: 768px;
$bpDesktop: 1024px;
$bpLgDesktop: 1366px;

/*
* Fonts
*/

$base-fontsize: 16px;

/*
* Color
*/

$body-text: #656565;

$pri-pink: #ef4e70;	// primary: for border, button, background
$sec-pink: #f95f80; // secondary: for Nickainley font title color
$ter-pink: #f36883; // tertiary: for lighter Lovelo font in writeup

$pink-2: #e41a45;

$header-bg-color: #ebe2da;
$footer-bg-color: #333333;

$event-box-bg: #404f7d;

$grey-1: #454545;
